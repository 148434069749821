import { DOMAIN, SECURE } from './cookies-config.js';

export const LOCAL_STORAGE_KEYS = {
	DEVICE_ID: 'device_id',
	AOFL_TOKEN: 'aofl_token',
	SOURCE_INFO: 'source_info'
};

export const AOFL_PRODUCT_NAMES = {
	ABCMOUSE: 'abcmouse_v2'
};

export const EVENT_NAMES = {
	CAMPAIGN_CLICK: 'marketing.campaign_click',
	LINK_CLICK: 'marketing.page_link_click',
	PAGE_LOAD: 'marketing.page_load',
	PIXEL_FIRE: 'marketing.pixel_fire',
	ROTATION_ASSIGN: 'marketing.rotation_assignment',
	VIDEO_PROGRESS: 'marketing.video_watched'
};

export const PLATFORMS = {
	DEFAULT: 'default_platform'
};

export const ACTION_SOURCES = {
	EMAIL_SPECIAL_OFFER_UPGRADE: 'email_special_offer_upgrade',
	REG_PATH: 'regpath'
};

export const AOFL_PRODUCT_UUIDS = {
	ABCMOUSE_2_0: 'b2892456-6e59-11ee-b962-0242ac120002'
};

export const GTM_EVENT_NAMES = {
	CONVERSION: 'conversion',
	PROSPECT_REG_SUBMITTED: 'prospect-reg',
	LOGIN: 'login'
};

export const PAYMENT_TYPE = {
	CREDIT_CARD: 'paymentech',
	PAYPAL: 'paypal'
};

export const SUBSCRIPTION_TYPES = {
	NEW_SUBSCRIPTION: 'firstTimeSubscription',
	// covers both reactivation and buying additional products.
	EXISTING_SUBSCRIPTION: 'existingSubscription'
};

export const TRANSACTION_TYPE = {
	PREPROCESS: 'preprocess',
	PROCESS: 'process'
};

export const UsernameTypes = {
	EMAIL: 'email'
};

export const PAGES = {
	INTERNAL: {
		HOME: '/',
		SUBSCRIPTION: '/subscription',
		REACTIVATION: '/reactivation',
		UPGRADE: '/upgrade',
		PROSPECT_REGISTER: '/prospect-register',
		DOWNLOAD_APP: '/download-app',
		LOGIN: '/login',
		LOGOUT: '/logout',
		PARENT_SECTION: '/parent-section',
		IN_APP_DEEP_LINK: '/redirect/deep-link-to-in-app',
		RESET_PASSWORD: '/reset-password'
	},
	EXTERNAL: {
		ABCMOUSE: 'https://www.abcmouse.com',
		ABCMOUSE_SEO_GAMES_PAGE: 'https://www.abcmouse.com/games',
		AOFL_ABCMOUSE_COOKIE_POLICY: 'https://www.ageoflearning.com/abc-privacyandcookie/',
		AOFL_BRAND_ABCM: 'https://www.ageoflearning.com/brand/abcmouse_landing_v1',
		AOFL_FOR_SCHOOLS: 'https://www.ageoflearning.com/schools/',
		AOFL_DEVICES: 'https://www.ageoflearning.com/devices/aofl_devices_en.pdf',
		ACCESSIBILITY: 'https://www.ageoflearning.com/accessibility',
		CUSTOMER_SUPPORT: 'https://support.abcmouse.com',
		CUSTOMER_SUPPORT_CONTACT_US: 'https://support.abcmouse.com/hc/en-us/articles/360047572854',
		PRIVACY_PDF: 'https://img.abcmouse.com/pdf/privacy.pdf',
		PRIVACY_PORTAL:
			'https://privacyportal-cdn.onetrust.com/dsarwebform/a2692794-047c-4c18-b3bf-60d783ebc844/8205df50-001c-4e16-b5f9-d4e945bc612e.html',
		IPV6: 'https://ipv6.abcmouse.com/trk',
		ALLABOUT_DNT: 'http://www.allaboutdnt.org',
		CCPA_METRICS: 'https://www.ageoflearning.com/ccpa/',
		CUSTOMER_SUPPORT_FORGOT_EMAIL:
			'https://support.abcmouse.com/hc/en-us/articles/4413252218903-Account-Access',
		CUSTOMER_SUPPORT_FORGOT_PASSWORD:
			'https://support.plus.abcmouse.com/hc/en-us/articles/19158148938263-Account-Access',
		CUSTOMER_SUPPORT_FAQS:
			'https://support.abcmouse.com/hc/en-us/articles/360053552753-What-devices-should-I-use-with-ABCmouse-',
		AOFL_RESEARCH: 'https://www.ageoflearning.com/research/',
		GOOGLE_UCB_LEARN_MORE: 'https://support.google.com/googleplay/answer/11174377',
		HOMESCHOOLPLUS: 'https://www.homeschoolplus.com',
		HOMESCHOOLPLUS_SUBSCRIPTION: 'https://www.homeschoolplus.com/subscription/',
		SUNMAID_WALMART: 'https://www.walmart.com/cp/sunmaidbacktoschool/3335011',
		SUNMAID_SIGHT_WORDS_PDF: 'https://img.abcmouse.com/pdf/abcmouse_sunmaid_sight_words_rgb_06.pdf',
		SUNMAID_MATH_WITH_RAISINS_PDF: 'https://img.abcmouse.com/pdf/sunmaid_book_letter_12.pdf',
		SUNMAID_COLORING_PAGES_PDF: 'https://img.abcmouse.com/pdf/sunmaid_alphabet_coloring_pages.pdf',
		SUNMAID_LETTER_TRACING_PDF: 'https://img.abcmouse.com/pdf/abcmouse_letter_tracing.pdf',
		KID_SAFE: 'https://www.kidsafeseal.com/certifiedproducts/abcmouse.html',
		PRIVACY_DE: 'https://www.ageoflearning.com/abc-privacy-german',
		PRIVACY_SE: 'https://www.ageoflearning.com/abc-privacy-swedish',
		TANDC_DE: 'https://www.ageoflearning.com/abc-tandc-german',
		TANDC_SE: 'https://www.ageoflearning.com/abc-tandc-swedish',
		PRIVACY_HUB: 'https://www.ageoflearning.com/privacy-policies/',
		PRIVACY_POLICY: 'https://www.ageoflearning.com/abc-privacy-current/',
		PRIVACY_ES: 'https://www.ageoflearning.com/abc-privacy-current-es/',
		TERMS_CONDITIONS: 'https://www.ageoflearning.com/abc-tandc-current/',
		TERMS_CONDITIONS_ES: 'https://www.ageoflearning.com/abc-tandc-current-es/',
		TIKTOK: 'https://www.tiktok.com/@abcmouse',
		FACEBOOK: 'https://www.abcmouse.com/outsideurl?url=facebook',
		FACEBOOK_PAGE: 'https://www.facebook.com/ABCmouse/',
		FACEBOOK_GROUP: 'https://www.facebook.com/groups/abcmouse.orientation/about/',
		INSTAGRAM: 'https://www.instagram.com/abcmouse/',
		PINTEREST: 'https://www.abcmouse.com/outsideurl?url=pinterest',
		PINTEREST_PAGE: 'https://www.pinterest.com/abcmouse/',
		CAREERS: 'https://www.ageoflearning.com/careers/',
		AOFL: 'https://www.ageoflearning.com',
		MEDIA: 'https://www.ageoflearning.com/media/',
		IOS_APP_STORE: 'https://apps.apple.com/ca/app/abcmouse-2-0/id6460300848',
		GOOGLE_APP_STORE:
			'https://play.google.com/store/apps/details?id=com.aofl.abcmouse&hl=en_US&gl=CA&pli=1'
	}
};

export const OUTBOUND_PATH = (currentUrl = '') => {
	// TODO: remove this for a env var based solution.
	const isDevEnv = currentUrl.includes('dev') || currentUrl.includes('localhost');
	if (isDevEnv) {
		return {
			SUCCESS_SUB_DEEP_LINK_TO_APP: 'https://dev.app.abcmouse.com/refreshToken?token=',
			FAILURE_SUB_DEEP_LINK_TO_APP: 'https://dev.app.abcmouse.com?token='
		};
	}
	return {
		SUCCESS_SUB_DEEP_LINK_TO_APP: 'https://app.abcmouse.com/refreshToken?token=',
		FAILURE_SUB_DEEP_LINK_TO_APP: 'https://app.abcmouse.com?token='
	};
};

export const QUERY_PARAM_KEYS = {
	SOURCE_TAG: 'src_tag',
	PRODUCT_TYPE: 'b_term',
	PRODUCT_HASH: 'productHash',
	GOTO: 'to',
	ENV: 'env',
	TOKEN: 'token'
};

export const QUERY_PARAM_VALUES = {
	B_TERM: {
		MONTHYL: 'monthly',
		ANNUAL: 'annual'
	}
};

export const CAMPAIGN_PIXEL_TYPES = {
	CONVERSION: 'conversion',
	LANDING: 'landing',
	PROSPECT_REGISTER: 'sign-up'
};

export const REG_PATH_KEYS = {
	DEFAULT_SUBSCRIBER: 'defaultSubscriber',
	DEFAULT_RESUBSCRIBER: 'defaultResubscriber',
	DEFAULT_IN_APP_CONSUMPTION: 'defaultInAppConsumption',
	DEFAULT_IN_APP_CONSUMPTION_RESUBSCRIBE: 'defaultInAppConsumptionResubscribe'
};

export const REG_PATH_STEPS = {
	COMPLETE: 'REGPATH_COMPLETE',
	SUBSCRIBED: 'REGPATH_SUBSCRIBED', // Step at which the user has subscribed.
	UPGRADE: 'REGPATH_UPGRADE',
	RESET: 'DEFAULT_STEP' // Step to reset a reg-path: should not be included in any reg-path, used primarily for reactivation users.
};

export const SUBSCRIPTION_HASHES = {
	PAYMENT_TECH: {
		monthly_fmf: '56bc8a40e5672b15da6ba318dbf91b5fab0d8b68ef5b93c2b42b5a9109ad90ce', // $12.99
		monthly_no_trial: '872652378a49578c6a4d5c2d1b4eb959b7fe04cbdf0a87a30c02d184bedca8b7', // $12.99
		semi_annual: '', //
		annual: '9548682862d5569abf2a9892815ebcfb3d6d1625598f0807378f450318c5e347', // $45.00
		upgrade: '', //
		monthly_reactivation: '872652378a49578c6a4d5c2d1b4eb959b7fe04cbdf0a87a30c02d184bedca8b7', // $
		semi_annual_reactivation: '', //
		annual_reactivation: '9548682862d5569abf2a9892815ebcfb3d6d1625598f0807378f450318c5e347', // $
		annual_reactivation_upgrade: '' //
	}
};

export const SUBSCRIPTION_STATUS = {
	OPTED_IN: 'opted_in',
	OPTED_OUT: 'opt_out',
	UNSUBSCRIBED: 'unsubscribed'
};

export const COOKIES = {
	CONFIG: {
		secure: SECURE,
		domain: DOMAIN,
		expires: 365
	},
	CJ_COOKIE: 'cje',
	COUNTRY_CODE: 'abcmouseCountryCode',
	GDPR: 'gdpr'
};

export const COOKIES_CONSENT = {
	ANALYTICS: 'ANALYTICS_PERFORMANCE_COOKIES',
	TARGETING: 'TARGETING_COOKIES'
};

// TODO: merge this, no reason to have a separate list.
/** @type {{[key:string]: string; }} */
export const COUNTRY_CODES_GDPR = {
	AT: 'Austria',
	AU: 'Australia',
	BE: 'Belgium',
	BG: 'Bulgaria',
	CY: 'Republic of Cyprus',
	CZ: 'Czech Republic',
	DE: 'Germany',
	DK: 'Denmark',
	EE: 'Estonia',
	ES: 'Spain',
	EU: 'European Union',
	FI: 'Finland',
	FR: 'France',
	GB: 'United Kingdom',
	GR: 'Greece',
	HU: 'Hungary',
	HR: 'Croatia',
	IE: 'Ireland',
	IT: 'Italy',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	LV: 'Latvia',
	MT: 'Malta',
	NL: 'Netherlands',
	PL: 'Poland',
	PT: 'Portugal',
	RO: 'Romania',
	SE: 'Sweden',
	SK: 'Slovakia',
	SI: 'Slovenia',
	GDPR: 'GDPR'
};

export const COUNTRY_CODES_OPTIONAL_EMAIL_CAPTURE = {
	AU: 'AU',
	CA: 'CA'
};

export const CURRENCY_CODE = {
	AUSTRALIAN_DOLLAR: 'AUD',
	CANADIAN_DOLLAR: 'CAD',
	US_DOLLAR: 'USD'
};
